<template>
  <div>
    <img id="logo" src="../../../public/tdt.svg" style="width:300px"/>
    <h1 id="content">페이지를 찾을 수 없습니다.</h1>
    <b-button id="button" variant="outline-primary" @click="goMain">메인으로</b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton
  },
  data() {
    return {};
  },
  methods: {
    goMain() {
          	this.$router.push({name:"event"});
    },
  }
};
</script>
<style scoped>
#logo {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
}
#content {
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#button {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
}
</style>
